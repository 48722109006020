import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import IdleVue from 'idle-vue'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Idle timeout
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000, // 5 minutes
  startAtIdle: false,
})

// Vue.use(VueReCaptcha, {
//   siteKey: '6LeJGTQcAAAAABnEQcYUPJkCqRKVBZm6hnZ_Ywrd',
//   loaderOptions: {
//     useRecaptchaNet: true,
//   },
// })

// const recaptureInst = new Vue({
//   methods: {
//     async recapture() {
//       await this.$recaptchaLoaded()

//       const token = await this.$recaptcha('login')
//       console.log(`recap token: ${token}`)
//       console.log(`recap token: ${recaptureInst}`)
//     },
//   },
//   template: 'button @click="recpatcha">Excecute recaptcha</button>',
// })

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$siteUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:8000/api/'

new Vue({
  router,
  store,
  // onIdle() {
  //   alert('You are not doing anything')
  // },
  // onActive() {
  //   this.messageStr = 'Active'
  // },
  render: h => h(App),
}).$mount('#app')

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')

/*
// Ensure we're logged in, if not re-direct to login page
router.beforeEach((to, from, next) => {
  console.log('routing')
  if (to.matched.some(record => record.meta.requiresLogin)) {
    console.log('checklogin')
    if (store.getters.loggedIn) {
      console.log('not logged in')
      next({ name: 'login' })
    } else {
      console.log('logged in so going to page')
      next()
    }
  } else {
    console.log('login not required')
    if (from === next) {
      next('/')
    } else {
      next()
    }
  }
})
*/
