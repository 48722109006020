import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:8000/api/',
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
